html, body, .container {
  height: 100%;
}

.container {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.page-content {
  text-align: center;
  max-width: 540px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 12px 0;
}

.bpm-outer-box {
  background: linear-gradient(to bottom right, var(--bs-secondary), var(--bs-pink));
  border-radius: 16px;
  margin: 24px 0;
  padding: 8px;
}

.bpm-box {
  background-color: var(--bs-body-bg);
  border-radius: 10px;
  padding: 8px 48px;
}

.bpm-display {
  font-size: 128px;
  font-weight: 800;
}
/*# sourceMappingURL=index.c04e070a.css.map */
