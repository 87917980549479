/**
 * BPM Counter | app style
 */

html,
body,
.container {
    height: 100%;
}

.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.page-content {
    max-width: 540px;

    padding: 12px 0;
    margin-top: auto;
    margin-bottom: auto;

    text-align: center;
}

.bpm-outer-box {
    padding: 8px;
    margin: 24px 0;

    background: linear-gradient(
        to bottom right,
        var(--bs-secondary),
        var(--bs-pink)
    );
    border-radius: 16px;
}

.bpm-box {
    padding: 8px 48px;

    background-color: var(--bs-body-bg);
    border-radius: 10px;
}

.bpm-display {
    font-size: 128px;
    font-weight: 800;
}
